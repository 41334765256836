<template>
  <th @click="$emit('selectCell', day)"
      :class="cellClasses">
    <div class="prompt_container" v-if="getLessons.length > 0">
      <div class="container__content">
          <RouterLink class="content__item" v-for="lesson in getLessons" :key="lesson.id"
                      :to="isPractice(lesson) ? {} : {name: 'lesson', params: {id: lesson.courseId, lessonId: lesson.id}}"
                      @click="openPayment($event, lesson, isPractice(lesson))">
            <div class="time" :style="{backgroundColor: lesson.color}" :class="{unpaid: !lesson.paid && !lesson.removable}">
              <p><span v-if="isPractice(lesson)">Практика: </span>{{ lesson.time_of }}-{{ lesson.time_to }}</p>
            </div>
            <span v-if="!isPractice(lesson) && !lesson.removable" class="button primary zoom" target="_blank" style="font-size: 10px;padding: 2px 7px 2px 7px;margin-left: 10px;">{{ lesson.name }}</span>
            <button v-if="lesson.removable" class="remove" @click.prevent="removePractice(lesson)">
              <i class="fas fa-trash"></i>
            </button>
            <div class="name">
              <p v-if="!isPractice(lesson)">{{ lesson.courseName }}</p>
              <template v-else>
                <p>
                  {{ lesson.category?.name }}
                  <template v-if="lesson.vessel">
                    ({{ lesson.vessel }})
                  </template>
                </p>
                <p class="nowrap">{{ lesson.address }}</p>
                <p>
                  {{ getTeacherName(lesson.teacher) }}
                  <template v-if="lesson.teacher?.phone">
                    ({{ lesson.teacher?.phone }})
                  </template>
                </p>
              </template>
            </div>
          </RouterLink>
      </div>
      <div class="prompt_close">
        <button class="btn_close" @click.stop="$emit('selectCell', null)">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <span class="calendar__value">{{ day.day }}</span>
    <div class="lection">
      <div class="lection__item" v-for="lesson in getLessons" :key="lesson.id"
           :style="{backgroundColor: lesson.color}" :class="{unpaid: !lesson.paid && !lesson.removable}">
        <p>{{ lesson.time_of }}</p>
      </div>
    </div>
  </th>
</template>

<script>
import {computed, toRefs} from "@vue/reactivity";
import {useRouter} from "vue-router";
import {inject} from "@vue/runtime-core";
import {teacherNameToObject, teacherFullName} from "@/helpers/teacher";

export default {
  props: ['day', 'selected'],
  setup(props) {
    const {day, selected} = toRefs(props);

    const getLessons = computed(() => {
      return day.value.lessons.filter(obj => {
          if (isPractice(obj)) return true;

          if (obj?.is_master_class || obj?.zoom_link) {
            return true;
          }

          return false;
        })
    })

    const cellClasses = computed(() => {
      const hasLessonsAndSelected = day.value.lessons.length && selected.value;
      const isSelected = hasLessonsAndSelected &&
          selected.value.day === day.value.day &&
          selected.value.another === day.value.another;

      return {
        current: day.value.current,
        prompt_open: isSelected,
        another_month: day.value.another
      };
    });

    const setUnpaid = inject('setUnpaid');
    const showPracticeModal = inject('showPracticeModal');
    const router = useRouter();
    function openPayment(event, lesson, isPractice = false) {
      event.preventDefault();
      if(lesson.paid && isPractice) {
        showPracticeModal(lesson)
        return;
      }

      if (lesson.removable) return;
      if (lesson.paid)
        return router.push({name: 'lesson', params: {id: lesson.courseId, lessonId: lesson.id}});
      setUnpaid(lesson);
    }

    function isPractice(lesson) {
      return !!lesson.teacher || lesson.removable;
    }

    function removePractice(lesson) {
      setUnpaid(lesson);
    }

    function getTeacherName(teacher) {
      if (!teacher) return 'Преподаватель назначается';
      return teacherFullName(teacherNameToObject(teacher));
    }

    return {cellClasses, getTeacherName, openPayment, isPractice, removePractice, getLessons}
  }
}
</script>

<style scoped lang="scss">
.unpaid {
  opacity: .3;
}
.remove {
  font-size: 8px;
  color: red;
}
.nowrap {
  white-space: nowrap;
}
</style>
