<template>
  <div class="sidebar">
    <div class="sidebar_title">Начать<br>
      обучение —<br>
      просто
    </div>
    <div class="sidebar-guide">
      <a href="#" class="item-guide" v-for="(step, i) in steps" :key="step.title"
         :class="{'item-guide__active': i <= currentIndex}" @click.prevent="changeStep(i)">
        <div class="item-guide__icon">{{ i + 1 }}</div>
        <div class="item-guide__title">{{ step.title }}</div>
      </a>
    </div>
    <div class="sidebar-price">

      <template v-if="user">
        <div class="sidebar-price__title">Баллы</div>
        <div class="sidebar-price__price">
          {{ user.bonus }}<br>
          <p style="font-size: 12px;line-height: 1.2;margin-bottom: 20px;">Использовать баллы возможно только при оплате</p>
        </div>
      </template>

      <div class="sidebar-price__title">Предварительная стоимость</div>
      <div class="sidebar-price__price">{{ numberFormat(summary) }} <CurrencyRuble/></div>
      <div class="sidebar-price__btns">
        <button class="button secondary wide" v-if="isFirst && !packageBuy"
                :class="{disabled: !readyToSwitch}" @click="next">Выбрать дату обучения
        </button>
        <template v-else>
          <template v-if="packageBuy">
            <RouterLink class="button primary big"
                        v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
            </RouterLink>
            <a class="button secondary wide"
               v-if="user && packageBuy && packagePrice?.link && packageCount > 0" :href="packagePrice?.link">Оплатить
            </a>
          </template>
          <template v-else>
            <button class="button secondary big" @click="prev">Назад</button>
            <button class="button primary big" :class="{disabled: !readyToSwitch}" @click="next"
                    v-if="!isLast">Далее
            </button>
            <RouterLink class="button primary big"
                        v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
            </RouterLink>
            <button class="button primary big"
                    v-if="isLast && user && readyToSwitch" @click="register">Завершить
            </button>
          </template>
        </template>
      </div>
    </div>
  </div>
  <div class="mobile_footer">
    <div class="mobile_footer__left">
      <div class="title">Стоимость</div>
      <div class="price">{{ numberFormat(summary) }} <CurrencyRuble/></div>
    </div>
    <div class="mobile_footer__right">
      <button class="button secondary" v-if="isFirst && !packageBuy"
              :class="{disabled: !readyToSwitch}" @click="next">Выбрать дату обучения
      </button>
      <template v-else>
        <template v-if="packageBuy">
          <a class="button secondary"
                      v-if="user && packageBuy && packagePrice?.link && packageCount > 0" :href="packagePrice?.link">Оплатить
          </a>
          <RouterLink class="button primary"
                      v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
          </RouterLink>
        </template>
        <template v-else>
          <button class="button secondary" @click="prev">Назад</button>
          <button class="button primary" :class="{disabled: !readyToSwitch}" @click="next"
                  v-if="!isLast">Далее
          </button>
          <RouterLink class="button primary"
                      v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
          </RouterLink>
          <button class="button primary" :class="{disabled: !readyToSwitch}"
                  v-if="isLast && user && readyToSwitch" @click="register">Завершить
          </button>
        </template>
      </template>
    </div>
  </div>
  <component :is="currentStep.component"
             :data="currentStep.data"
             :steps="steps"
             v-model="practice"
             :package="{packageBuy: packageBuy, packageCount: packageCount}"
             @packageUpdate="packageBuy = $event"
             @packageCountUpdate="packageCount = $event"/>
  <BaseModal :show="isErrorModalShowing"
             @cancel-modal="isErrorModalShowing = false"
             title="Выбранное время уже забронировано"
             description="Пожалуйста, проверьте время бронирования и исправьте ошибки">
    <button class="button primary" @click="isErrorModalShowing = false">Вернуться</button>
    <button class="button secondary red" @click="clearSetup">Прервать</button>
  </BaseModal>
</template>

<script>

import PracticeView from "@/components/setup/PracticeView";
import {inject, onMounted, provide, readonly, ref, watch} from "@vue/runtime-core";
import {getPractice, registerPractice, getPricePractice} from "@/data/requests";
import numberFormat from "@/helpers/numberFormat";
import DatesView from "@/components/setup/DatesView";
import {useSteps} from "@/use/steps";
import {useLocalStore} from "@/use/localStore";
import {computed} from "@vue/reactivity";
import {
  clearRegisterErrors,
  getRegisterErrors,
  preparePracticeData,
  preparePracticeErrors,
  preparePracticeRegisterData, preparePracticeTimes
} from "@/helpers/courseRegister";
import {useRouter} from "vue-router";
import BaseModal from "@/components/common/BaseModal";
import CurrencyRuble from "@/components/common/CurrencyRuble";

export default {
  components: {CurrencyRuble, BaseModal},
  setup() {
    const router = useRouter();
    const user = inject('user');
    const token = inject('token');
    const practices = ref([]);
    // Переменные для пакетной покупки
    const packageBuy = ref(false)
    const packageCount = ref(1)
    const packagePrice = ref({})
    const currentRouteName = ref(router.currentRoute.value.name)

    onMounted(async () => practices.value = preparePracticeData(await getPractice(token.value, false, router.currentRoute.value.name === 'practice-iyt')));

    watch(router.currentRoute, async () => {
      currentRouteName.value = router.currentRoute.value.name
      practices.value = await preparePracticeData(await getPractice(token.value, router.currentRoute.value.name === 'practice-iyt'))
    })

    const {
      clear: clearPracticeData,
      data: practiceData,
      update: updatePracticeData
    } = useLocalStore('practiceSetup', {});
    provide('practiceData', readonly(practiceData));
    provide('updatePracticeData', updatePracticeData);

    watch(packageCount, async () => {
      if(packageBuy.value)
        if(practiceData.value.practiceIds?.length > 0)
          packagePrice.value = await getPricePractice(token.value, practiceData.value.practiceIds[0], packageCount.value)
        else
          packagePrice.value = 0
    }, { deep: true })

    const summary = computed( () => {
      if(packageBuy.value) {
        return packagePrice.value?.price ?? 0
      }
      const {times: timesIds, individuals} = practiceData.value;
      const lessons = preparePracticeTimes(practices.value);
      const times = timesIds?.map(id => {
        const found = lessons.find(el => el.id === id);
        return {
          ...found,
          individual: individuals?.includes(id) ?? false
        }
      }) ?? [];
      const [, ...discountItems] = times.sort((a, b) => b.discount - a.discount);
      const discount = discountItems.reduce((acc, el) => acc + (el.discount ?? 0), 0);
      const sum = times.reduce((acc, el) => (acc + (el.price_for_lesson ?? el.price) + (el.individual ? el.individual_price : 0)), 0)
      return sum - discount - (times.length && times[0].has_discount ? times[0].discount : 0);
    });

    const datesData = computed(() => practices.value?.filter(el => practiceData.value.practiceIds?.includes(el.id)));

    const steps = computed(() => {
      return [
        {
          title: 'Выбери судно',
          component: PracticeView,
          data: practices.value,
          readyFunc: () => practiceData.value.practiceIds?.length > 0
        },
        ...(packageBuy.value ?
            [] :
            [{
              title: 'Дата обучения',
              component: DatesView,
              data: datesData.value,
              readyFunc: () => practiceData.value.times?.length > 0
            }])
      ]
    });

    const practice = computed({
      get: () => practiceData.value,
      set: value => updatePracticeData(value)
    });

    const isErrorModalShowing = ref(false);
    const registerErrors = ref(preparePracticeErrors(
        getRegisterErrors() ?? {},
        preparePracticeRegisterData(practiceData.value)
    ));
    const errorsIds = computed(() => registerErrors.value.map(el => el[0]));
    const errorsMap = computed(() => Object.fromEntries(registerErrors.value));
    provide('errorsIds', errorsIds);
    provide('errorsMap', errorsMap);

    async function register() {
      clearRegisterErrors();
      registerErrors.value = [];
      const preparedData = preparePracticeRegisterData(practiceData.value);
      const data = await registerPractice(token.value, preparedData);

      if (data.errors) {
        isErrorModalShowing.value = true;
        registerErrors.value = preparePracticeErrors(data.errors, preparedData);
        return;
      }

      await router.push({name: 'payment', params: {id: 'practices'}});
      clearPracticeData();
    }

    async function clearSetup() {
      isErrorModalShowing.value = false;
      await router.push({name: "timetable"});
      clearRegisterErrors();
      clearPracticeData();
    }

    provide('clearSetup', clearSetup);

    let {changeStep, currentIndex, currentStep, next, prev, readyToSwitch, isFirst, isLast} = useSteps(steps, currentRouteName.value);

    return {
      steps,
      changeStep,
      currentIndex,
      currentStep,
      isFirst,
      isLast,
      next,
      prev,
      readyToSwitch,
      numberFormat,
      user,
      practice,
      summary,
      register,
      isErrorModalShowing,
      clearSetup,
      packageBuy,
      packageCount,
      packagePrice
    }
  }
}
</script>
