<template>
  <div class="content">
    <div class="content-header">
      <button class="content-header__exit" @click="logout">Выйти</button>
      <RouterLink :to="{name: 'user'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <div class="success" v-if="success">{{ success }}</div>
      <div class="user-info">
        <div class="user-info_name">
          <div class="user-info_fullname">{{ fullName }}</div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">Фото (3х4, цветное)</div>
          <AvatarLoader @success="setSuccess"/>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">Личные данные</div>
          <div class="user-info_item">
            <div class="user-info_item__title">Фамилия:</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.last_name">
                <input class="input" type="text" placeholder="Иванов" v-model="form.last_name">
              </FormField>
            </div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Имя:</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.first_name">
                <input class="input" type="text" placeholder="Иван" v-model="form.first_name">
              </FormField>
            </div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Отчество:</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.middle_name">
                <input class="input" type="text" placeholder="Иванович" v-model="form.middle_name">
              </FormField>
            </div>
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">Контакты</div>
          <div class="user-info_item">
            <div class="user-info_item__title">Email:</div>
            <div class="user-info_item__value">
              <FormField class="field">
                <input class="input" type="text" disabled :value="user?.email" placeholder="example@domain.com">
              </FormField>
            </div>
          </div>
          <PhoneConfirm :phone="user?.phone" :verified="user?.phone_verified" @success="setSuccess"/>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">Пароль</div>
          <ChangePassword @success="setSuccess"/>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">IYT ID</div>
          <div class="user-info_item">
            <div class="user-info_item__title">IYT ID:</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.iyt_id">
                <input class="input" type="text" v-model="form.iyt_id" placeholder="IYT ID">
              </FormField>
            </div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Действует до:</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.iyt_date_to">
                <input class="input" type="date" v-model="form.iyt_date_to" placeholder="Действует до">
              </FormField>
            </div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Категория IYT</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.iyt_category">
                <select class="input" v-model="form.iyt_category">
                  <option value>не указана</option>
                  <option value="small">Small power boat & RIB master</option>
                  <option value="personal">Personal watercraft</option>
                  <option value="bareboat">Bareboat Skipper</option>
                  <option value="coastal">Coastal</option>
                  <option value="offshore">Offshore</option>
                  <option value="ocean">Ocean</option>
                </select>
              </FormField>
            </div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Категория BBS</div>
            <div class="user-info_item__value">
              <FormField class="field" :error="errors.iyt_bbs_category">
                <select class="input" v-model="form.iyt_bbs_category">
                  <option value>не указана</option>
                  <option value="sail">Sail</option>
                  <option value="power">Power</option>
                  <option value="power_sail">Power&Sail</option>
                </select>
              </FormField>
            </div>
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Документы
          </div>
          <div class="user-info__documents">
            <div class="user-info__document">
              <div class="user-info__document__title">Паспорт РФ</div>
              <div class="user-info__document__subtitle">Заполняйте, пожалуйста, строго как в паспорте.</div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Серия паспорта</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.passport_serial">
                      <input id="passport_serial" oninput="this.value = this.value.replace(' ', '')" class="input" type="text" v-model="form.passport_serial" placeholder="1234">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Номер паспорта</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.passport_num">
                      <input id="passport_num" oninput="this.value = this.value.replace(' ', '')" class="input" type="text" v-model="form.passport_num" placeholder="567890">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Место рождения</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.passport_place">
                      <input id="passport_place" class="input" type="text" v-model="form.passport_place" placeholder="г. Москва">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Гражданство</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.citizenship">
                      <input id="citizenship" class="input" type="text" v-model="form.citizenship" placeholder="">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Адрес регистрации (по паспорту)</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.passport_address">
                      <input id="passport_address" class="input" type="text" v-model="form.passport_address" placeholder="г. Москва, ул. Кораблестроителей, д. 12, кв 123">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Кем выдан</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.passport_issued">
                      <input class="input" type="text" v-model="form.passport_issued" placeholder="ТП №10 Отдела УФМС России по Москве и Московской обл. в Московском р-не г. Москвы">
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Когда выдан</div>
                  <div class="user-info__document_item__value">
                    <date-picker
                        v-model="form.passport_issued_at"
                        locale="ru"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="input"
                            :value="inputValue"
                            v-on="inputEvents"
                        >
                      </template>
                    </date-picker>
                  </div>
                </div>
              </div>


              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Дата рождения</div>
                  <div class="user-info__document_item__value">
                    <date-picker
                        v-model="form.birth_date"
                        locale="ru"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="input"
                            :value="inputValue"
                            v-on="inputEvents"
                        >
                      </template>
                    </date-picker>
                  </div>
                </div>
              </div>


              <div class="user-info__document_blocks">
                <div class="w100">
                  <div class="user-info__document_item__title">Мед. справка</div>
                  <div class="user-info__document_item__value">
                    <FormField class="field" :error="errors.medical_number">
                      <input class="input" type="text" v-model="form.medical_number" placeholder="Название организации, номер, дата выдачи">
                    </FormField>
                  </div>
                </div>
              </div>

            </div>
            <EditDocuments type="main" :documents="user?.documents" />
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Заграничный паспорт
          </div>
          <div class="user-info__documents">
            <EditDocuments type="other" :documents="user?.other_documents" />
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Мед. справка
          </div>
          <div class="user-info__documents">
            <EditDocuments type="med" :documents="user?.med_documents" />
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Международные права IYT ID
          </div>
          <div class="user-info__documents">
            <EditDocuments type="iyt" :documents="user?.iyt_documents" />
          </div>
        </div>
        
        <div style="margin-bottom: 20px;">
          <button class="button primary" @click="updateUserInfo">Сохранить</button>
        </div>

        <div class="user-info__group" v-if="0">
          <div class="user-info__title">
            Скидки
          </div>
          <div class="user-info__documents">
            <EditDiscounts :discounts="user?.discounts" />
          </div>
        </div>

      </div>

    </div>
  </div>
  <div class="support" style="display:none;">
    <div class="support_btn"><button><i class="far fa-comment-alt"></i></button></div>
  </div>
</template>

<script>
import {inject, ref, watch} from "@vue/runtime-core";
import FormField from "@/components/common/FormField";
import PhoneConfirm from "@/components/user/PhoneConfirm";
import ChangePassword from "@/components/user/ChangePassword";
import {editProfile} from "@/data/requests";
import {prepareUserEditForm} from "@/helpers/user";
import {useRouter} from "vue-router";
import {wait} from "@/helpers/wait";
import EditDocuments from "@/components/user/EditDocuments";
import EditDiscounts from "@/components/user/EditDiscounts";
import {computed} from "@vue/reactivity";
import AvatarLoader from "@/components/user/AvatarLoader";
// import Datepicker from 'vue3-datepicker'

import locale from 'date-fns/locale/ru'
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

export default {
  components: {
    AvatarLoader,
    EditDocuments,
    EditDiscounts,
    ChangePassword,
    PhoneConfirm,
    FormField,
    DatePicker,
  },
  setup() {
    const user = inject('user');
    const form = ref({});
    const passport_issued_at = ref('passport_issued_at');
    const birth_date = ref('birth_date');

    watch(user, value => form.value = prepareUserEditForm(value), {immediate: true});

    const errors = ref({});
    const token = inject('token');
    const updateProfileData = inject('updateProfileData');
    const router = useRouter();

    async function updateUserInfo() {
      errors.value = {};
      const preparedData = JSON.parse(JSON.stringify(form.value));
      const data = await editProfile(token.value, preparedData);
      if (data.errors) {
        errors.value = data.errors;
        return;
      }
      await updateProfileData(data);
      await router.push({name: 'user'});
    }

    const logout = inject('logout');

    const success = ref('');
    async function setSuccess(value) {
      success.value = value;
      await wait(5000);
      success.value = '';
    }

    const fullName = computed(() => {
      const {last_name, first_name, middle_name} = form.value;
      return `${last_name ?? ''} ${first_name ?? ''} ${middle_name ?? ''}`;
    });

    return {logout, user, form, errors, updateUserInfo, success, setSuccess, fullName, locale, passport_issued_at, birth_date}
  }
}
</script>

<style lang="scss">
.v3dp__input_wrapper .input {
  width: auto;
}
</style>

<style scoped lang="scss">
.input {
  margin-top: 0;
}

.sms {
  min-width: auto;
  width: 82px;
}
.field {
  margin-bottom: 0;
}
</style>
