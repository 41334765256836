import {apiRequest, http} from "@/helpers/http";
import {apiHost} from "@/config";
import axios from "axios";

export const throwIfUnauthorized = code => {
  if (code === 401)
    throw new Error('Unauthorized');
}

export const getUserProfile = async token => {
  const {data, code} = await apiRequest('profile', 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const getUserCourses = async token => {
  const {data, code} = await apiRequest('courses', 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const getLesson = async (token, id) => {
  const {data, code} = await apiRequest(`lesson/${id}`, 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  if (code === 404) return null;
  return data;
}

export const getLessonResult = async (token, id) => {
  const {data, code} = await apiRequest(`lesson/test/${id}/result`, 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  if (code === 404) return null;
  return data;
}

export const getTest = async (token, id) => {
  const {data, code} = await apiRequest(`lesson/test/${id}`, 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  if (code === 404) return null;
  return data;
}

export const sendTest = async (token, testID, requestData) => {
  const {data, code} = await apiRequest(`lesson/test/${testID}/send`, 'POST', {"Authorization": `Bearer ${token}`}, requestData)
  throwIfUnauthorized(code);
  return data;
}

export const register = async registerData => {
  return await apiRequest('register', 'POST', {}, registerData);
}

export const auth = async loginData => {
  const {data} = await apiRequest('auth', 'POST', {}, loginData);
  return data;
}

export const sendPasswordToEmail = async email => {
  await apiRequest('forgot-password', 'POST', {}, {email});
}

export const getCourses = async (token, isMasterClass = false) => {
  const {data} = await apiRequest('courses-info' + (isMasterClass ? '/?master-class=1' : ''), 'GET', {"Authorization": `Bearer ${token}`});
  return data;
}

export const getPractice = async (token, exam = false, iyt = false) => {
  const {data} = await apiRequest(`practices-info?exam=${~~exam}&iyt=${~~iyt}`, 'GET', {"Authorization": `Bearer ${token}`});
  return data;
}

export const getPricePractice = async (token, practiceId, count, useBonus = false) => {
  const {data} = await apiRequest(`practices/${practiceId}/batch-price?count=${count}&bonus=${useBonus ? '1' : '0'}`, 'GET', {"Authorization": `Bearer ${token}`});
  return data;
}

export const registerCourse = async (token, requestData) => {
  const {data, code} = await apiRequest('course-register', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const calculateCourse = async (token, requestData) => {
  const {data, code} = await apiRequest('group/calculate', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const registerPractice = async (token, requestData) => {
  const {data, code} = await apiRequest('practice-register', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const registerPracticeBatch = async (token, requestData) => {
  const {data, code} = await apiRequest('practices/batch/register', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const getCourseInfo = async (token, groupId, useBonus = false) => {
  const {data, code} = await apiRequest(`group/${groupId}?bonus=${useBonus ? 1 : 0}`, 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const paymentByBonus = async (token, groupId) => {
  const {data, code} = await apiRequest(`group-bonus/${groupId}?bonus=1`, 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const sendSupportMessage = async (token, requestData) => {
  const {data, code} = await apiRequest('support', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return {data};
}

export const getUserPractices = async token => {
  const {data, code} = await apiRequest('practices', 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const getUnpaidPractices = async (token, useBonus = false) => {
  const {data, code} = await apiRequest('practices-paid?bonus=' + (useBonus ? 1 : 0), 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const payPractice = async (token, useBonus = false) => {
  const {data, code} = await apiRequest('practices-paid/pay?bonus=' + (useBonus ? 1 : 0), 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const payPracticeBonus = async (token) => {
  const {data, code} = await apiRequest('practices-paid/pay-bonus?bonus=1', 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const removePractice = async (token, id) => {
  const {data, code} = await apiRequest(`practices/${id}`, 'DELETE', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const removeUnpaidPractices = async token => {
  const {data, code} = await apiRequest('practices', 'DELETE', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const forgotPassword = async email => {
  return await apiRequest('forgot-password', 'POST', {}, {email});
}

export const resetPassword = async requestData => {
  const {data} = await apiRequest('forgot-password-reset', 'POST', {}, requestData);
  return data;
}

export const loadDocuments = async (token, body, onUploadProgress, type = 'main') => {
  try {
    const {data, status} = await axios.request({
      url: `${apiHost}profile/documents/?type=${type}`,
      method: 'POST',
      headers: {"Authorization": `Bearer ${token}`},
      data: body,
      onUploadProgress
    });
    throwIfUnauthorized(status);
    return data;
  } catch (error) {
    throwIfUnauthorized(error.code);
    return error.response.data;
  }
}

export const uploadDiscount = async (token, id, body, onUploadProgress) => {
  try {
    const {data, status} = await axios.request({
      url: apiHost + 'discounts/' + id,
      method: 'POST',
      headers: {"Authorization": `Bearer ${token}`},
      data: body,
      onUploadProgress
    });
    throwIfUnauthorized(status);
    return data;
  } catch (error) {
    throwIfUnauthorized(error.code);
    return error.response.data;
  }
}

export const removeDocument = async (token, id) => {
  const {code} = await apiRequest(`profile/documents/${id}`, 'DELETE', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return code;
}

export const editProfile = async (token, requestData) => {
  const {data, code} = await apiRequest('profile', 'PATCH', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const getPayments = async (token) => {
  const {data, code} = await apiRequest('payments', 'GET', {"Authorization": `Bearer ${token}`});
  throwIfUnauthorized(code);
  return data;
}

export const changePassword = async (token, requestData) => {
  const {data, code} = await apiRequest('profile/password', 'PATCH', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const changePhone = async (token, phone) => {
  const {data, code} = await apiRequest('profile/phone', 'PATCH', {"Authorization": `Bearer ${token}`}, {phone});
  throwIfUnauthorized(code);
  return data;
}

export const acceptNewPhone = async (token, smsCode) => {
  const {
    data,
    code
  } = await apiRequest('profile/phone/accept', 'PATCH', {"Authorization": `Bearer ${token}`}, {code: smsCode});
  throwIfUnauthorized(code);
  return data;
}

export const changeAvatar = async (token, requestData) => {
  const {
    data,
    code
  } = await http(apiHost + 'profile/avatar', 'POST', {"Authorization": `Bearer ${token}`}, requestData);
  throwIfUnauthorized(code);
  return data;
}

export const getPossiblePracticesToTransfer = async (token, lesson) => {
  const {data} = await apiRequest(`practices/transfer/${lesson.id}`, 'GET', {"Authorization": `Bearer ${token}`});
  return data;
}

export const transferLessonTo = async (token, lesson, timeId) => {
  return apiRequest(`practices/transfer/${lesson.id}/${timeId}`, 'POST', {"Authorization": `Bearer ${token}`})
}

export const transferBatch = async (token, lessonId) => {
  return apiRequest(`practices/transfer-batch/${lessonId}`, 'POST', {"Authorization": `Bearer ${token}`})
}

export const getDocuments = async (token) => {
  const {data} = await apiRequest(`documents`, 'GET', {"Authorization": `Bearer ${token}`})
  return data;
}

export const getSiteInfo = async (token) => {
  const {data} = await apiRequest(`site-info`, 'GET', {"Authorization": `Bearer ${token}`})
  return data;
}

export const getCities = async () => {
  const {data} = await apiRequest(`cities`, 'GET');
  return data;
}

export const getTeams = async () => {
  const {data} = await apiRequest(`teams`, 'GET');
  return data;
}

export const getFleets = async () => {
  const {data} = await apiRequest(`fleets`, 'GET');
  return data;
}

export const getNotifications = async (token) => {
  const {data} = await apiRequest(`notifications`, 'GET', {"Authorization": `Bearer ${token}`})
  return data;
}

export const markAsReadNotification = async (token, notificationId) => {
  await apiRequest(`notifications/${notificationId}`, 'PATCH', {"Authorization": `Bearer ${token}`})
  return true;
}

export const markAsReadAllNotification = async (token) => {
  await apiRequest(`notifications/all`, 'PATCH', {"Authorization": `Bearer ${token}`})
  return true;
}

export const getPracticesForReviews = async (token) => {
  const {data} = await apiRequest(`practices/reviews`, 'GET', {"Authorization": `Bearer ${token}`})
  return data;
}

export const sendPracticesReview = async (token, timeId, requestData) => {
  return await apiRequest(`practices/reviews/${timeId}`, 'POST', {"Authorization": `Bearer ${token}`}, requestData)
}
