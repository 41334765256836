<template>
  <div class="user-info__document wrapper">
    <div class="user-info__document__title">{{ file.name }}</div>
    <img v-if="type === 'image'" class="preview" :src="image" alt="Документ">
    <div class="user-info__document_item__value">{{ formatFileSize(file.size) }}</div>
    <small v-if="error" class="error">{{ error }}</small>
    <div class="progress" v-if="sending"><div class="progress__value" :style="{ width: `${progress * 100}%` }"></div></div>
    <div class="buttons" v-else>
      <button class="button primary sm" @click="save">Загрузить</button>
      <button class="button secondary red sm" @click="remove">Отменить</button>
    </div>
  </div>
</template>

<script>
import {formatFileSize} from "@/helpers/formatFileSize";
import {inject, ref} from "@vue/runtime-core";
import {loadDocuments} from "@/data/requests";

export default {
  props: ['file', 'documentType'],
  emits: ['removeFile'],
  setup(props, {emit}) {
    const type = props.file.type.replace(/\/.+/, '');
    const image = URL.createObjectURL(props.file);

    function remove() {
      URL.revokeObjectURL(image);
      emit('removeFile');
    }

    const error = ref('');
    const token = inject('token');
    const sending = ref(false);
    const updateProfileData = inject('updateProfileData');
    const progress = ref(0);

    async function save() {
      progress.value = 0;
      error.value = '';
      sending.value = true;
      const formData = new FormData();
      formData.append('documents[]', props.file);

      const data = await loadDocuments(token.value, formData, ({loaded, total}) => progress.value = loaded / total, props.documentType);

      progress.value = 1;
      sending.value = false;
      if (data.errors) {
        error.value = Object.values(data.errors).join(', ');
        return;
      }
      await updateProfileData(data);
      remove();
    }

    return {type, image, formatFileSize, remove, error, sending, save, progress}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 232px;
  box-sizing: border-box;
  @media screen and (max-width: 1025px){
    width: auto;
  }
}
.preview {
  max-height: 100px;
  width: 100%;
  object-fit: cover;
}
.button {
  margin-top: 10px;
  &:last-of-type {
    margin-left: 10px;
  }
}
.error {
  color: red;
  margin-top: 8px;
  display: block;
  font-size: 12px;
}
.progress {
  height: 4px;
  border-radius: 4px;
  overflow: hidden;
  background: #ccc;
  &__value {
    height: 100%;
    background: #07a4b2;
    width: 0;
    border-radius: 4px;
    transition: width .1s;
  }
}
</style>
